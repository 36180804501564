import { useEffect, useState } from "react";
import { View, Pressable, Text } from "@/ui/index";
import { useUISlice } from "@/models/useUISlice";
import ModalContainer from "@/ui/ModalContainer";
import Input from "./Input";
import { useTranslation } from "react-i18next";
import { Colors } from "@/constants";
import { isNativeApp } from "@/models/platform";

const AlertPromptSignIn = () => {
  const prompt = useUISlice((state) => state.alertPromptSignIn);
  const setPrompt = useUISlice((state) => state.setAlertPromptSignIn);
  const [text, setText] = useState("");
  const width = useUISlice((state) => state.width);
  const { t } = useTranslation();
  const isNative = isNativeApp();

  useEffect(() => {
    if (!prompt) return;

    setText(prompt.defaultValue || "");
  }, [prompt]);

  const _onPress = () => {
    prompt.button && prompt.button.onPress(text);
  };

  if (!prompt) return null;

  if (isNative) {
    prompt.onGoogleSignIn = undefined;
  }

  return (
    <ModalContainer
      title={prompt.title}
      subtitle={prompt.subtitle}
      onDismiss={() => setPrompt(undefined)}
      primaryButtonText={t("alertPromptSignIn.signInWithEmail")}
      onPrimaryButtonPress={_onPress}
      hideDismissIcon={prompt.hideDismissIcon}
      hideCancel
      shouldPersistBackdropClick={prompt.shouldPersistBackdropClick}
      loading={prompt.loading}
      destructive={prompt.button && prompt.button.destructive}
      modalWidth={prompt.multiLine ? Math.min(width - 32, 500) : 360}
    >
      <View style={{ height: 16 }} />

      {prompt.onGoogleSignIn && (
        <>
          <Pressable
            style={{
              flexDirection: "row",
              backgroundColor: Colors.white,
              borderWidth: 1,
              borderColor: Colors.borderColor,
              height: 40,
              width: "100%",
              borderRadius: 8,
              alignItems: "center",
              justifyContent: "center",
            }}
            onPress={prompt.onGoogleSignIn}
          >
            <img
              width={20}
              height={20}
              src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCI+PHBhdGggZmlsbD0iIzQyODVGNCIgZD0iTTIyLjU2IDEyLjI1YzAtLjc4LS4wNy0xLjUzLS4yLTIuMjVIMTJ2NC4yNmg1LjkyYy0uMjYgMS4zNy0xLjA0IDIuNTMtMi4yMSAzLjMxdjIuNzdoMy41N2MyLjA4LTEuOTIgMy4yOC00Ljc0IDMuMjgtOC4wOXoiLz48cGF0aCBmaWxsPSIjMzRBODUzIiBkPSJNMTIgMjNjMi45NyAwIDUuNDYtLjk4IDcuMjgtMi42NmwtMy41Ny0yLjc3Yy0uOTguNjYtMi4yMyAxLjA2LTMuNzEgMS4wNi0yLjg2IDAtNS4yOS0xLjkzLTYuMTYtNC41M0gyLjE4djIuODRDMy45OSAyMC41MyA3LjcgMjMgMTIgMjN6Ii8+PHBhdGggZmlsbD0iI0ZCQkMwNSIgZD0iTTUuODQgMTQuMDljLS4yMi0uNjYtLjM1LTEuMzYtLjM1LTIuMDlzLjEzLTEuNDMuMzUtMi4wOVY3LjA3SDIuMThDMS40MyA4LjU1IDEgMTAuMjIgMSAxMnMuNDMgMy40NSAxLjE4IDQuOTNsMi44NS0yLjIyLjgxLS42MnoiLz48cGF0aCBmaWxsPSIjRUE0MzM1IiBkPSJNMTIgNS4zOGMxLjYyIDAgMy4wNi41NiA0LjIxIDEuNjRsMy4xNS0zLjE1QzE3LjQ1IDIuMDkgMTQuOTcgMSAxMiAxIDcuNyAxIDMuOTkgMy40NyAyLjE4IDcuMDdsMy42NiAyLjg0Yy44Ny0yLjYgMy4zLTQuNTMgNi4xNi00LjUzeiIvPjxwYXRoIGZpbGw9Im5vbmUiIGQ9Ik0xIDFoMjJ2MjJIMXoiLz48L3N2Zz4="
            />
            <Text
              style={{
                marginLeft: 12,
                color: Colors.text,
                fontSize: 16,
                fontWeight: "500",
              }}
            >
              {t("alertPromptSignIn.signInWithGoogle")}
            </Text>
          </Pressable>

          <Text
            style={{
              textAlign: "center",
              marginVertical: 20,
              color: Colors.subtitle,
            }}
          >
            {t("alertPromptSignIn.or").toUpperCase()}
          </Text>
        </>
      )}

      <Input
        disabled={prompt.loading}
        height={prompt.multiLine ? 220 : undefined}
        multiLine={prompt.multiLine}
        value={text}
        placeholder={prompt.placeholder}
        onTextChange={(text) => setText(text)}
      />
    </ModalContainer>
  );
};

export default AlertPromptSignIn;
