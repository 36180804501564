import React, { useState } from "react";
import { Image, Pressable, View } from "@/ui";
import { ImageStyle, ViewStyle } from "react-native";
import { Colors } from "@/constants/Colors";

export const icons = {
  emojiTarget: "emoji_target.svg",
  emojiInfinite: "emoji_infinite.png",
  emojiFire: "emoji_fire.svg",
  emojiMedia: "emoji_media.png",
  check: "check.png",
  upload: "upload.png",
  arrowDown: "arrow_down.png",
  support: "support.png",
  settings: "icon_settings.png",
  export: "export.png",
  trash: "trash.png",
  moveFolder: "move_folder.png",
  caretDown: "caret_down.png",
  clock: "clock.png",
  calendar: "calendar.png",
  caretUp: "caret_up.png",
  unlimited: "unlimited.png",
  tick: "tick.png",
  emojiTranscript: "emoji_transcript.png",
  editFile: "edit_file.png",
  logo: "logo.png",
  logoApp: "logo_app.png",
  emojiEarth: "emoji_earth.svg",
  emojiExplodingHead: "emoji_exploding.svg",
  emojiX: "emoji_x.png",
  openai: "openai.png",
  emojiLock: "emoji_lock.svg",
  close: "close.svg",
  folder: "folder.svg",
  folderFull: "folder_full.svg",
  mic: "mic.svg",
  emojiStar: "emoji_star.svg",
  plus: "plus.png",
  emojiSpeaker: "emoji_speaker.svg",
  incomplete: "incomplete.png",
  more: "more.svg",
  newFolder: "new_folder.svg",
  recent: "recent.svg",
  premium: "premium.png",
  recentFull: "recent_full.svg",
  advancedExport: "advanced_export.svg",
  docx: "docx.svg",
  pdf: "pdf.svg",
  txt: "txt.svg",
  srt: "srt.svg",
  share: "share.svg",
  downloadAndroid: "download_android.svg",
  downloadIos: "download_ios.svg",
  downloadTranscript: "download_transcript.svg",
  timeForward: "time_forward.png",
  timeBackward: "time_backward.png",
  pause: "pause.png",
  play: "play.png",
  appStore: "app_store.svg",
  playStore: "play_store.svg",
  downloadAppStore: "download_ios.svg",
  downloadPlayStore: "download_android.svg",
};

export type IconType = keyof typeof icons;

const Icon = ({
  icon,
  onPress,
  style = {},
  containerStyle = {},
  disabled = false,
}: {
  icon: keyof typeof icons;
  disabled?: boolean;
  onPress?: () => void;
  style?: ImageStyle;
  containerStyle?: ViewStyle;
}) => {
  const [active, setActive] = useState(false);

  if (!onPress)
    return (
      <View style={containerStyle || {}}>
        <Image
          source={{ uri: `/${icons[icon]}` }}
          alt={icon}
          style={{ width: 18, height: 18, ...style }}
        />
      </View>
    );

  return (
    <Pressable
      onPress={onPress}
      disabled={disabled}
      onHoverIn={() => setActive(true)}
      onHoverOut={() => setActive(false)}
      style={{
        backgroundColor: active ? Colors.hover : "transparent",
        width: 48,
        height: 48,
        alignItems: "center",
        justifyContent: "center",
        borderRadius: 200,
        ...containerStyle,
      }}
    >
      <Image
        source={{ uri: `/${icons[icon]}` }}
        alt={icon}
        style={{ width: 18, height: 18, ...style }}
      />
    </Pressable>
  );
};

export default Icon;
