import { useState } from "react";
import { datadogLogs } from "@datadog/browser-logs";
import { View } from "@/ui";
import { useUISlice } from "@/models/useUISlice";
import ModalContainer from "@/ui/ModalContainer";
import { useUserSlice } from "@/models/useUserSlice";
import { audioLanguages } from "@/constants";
import PickerSelectLanguage from "@/ui/PickerSelectLanguage";
import PrimaryButton from "@/ui/PrimaryButton";
import * as serverClient from "@/models/serverClient";
import { useTranslation } from "react-i18next";
import { useRouter } from "@/_router";
import { getLocalizedBasePath } from "@/models/utils";
import { useAuth } from "@/models/auth";
import toast from "react-hot-toast";
import Paywall from "@/ui/Paywall";
import Checkbox from "@/ui/Checkbox";
import { useUpload } from "@/models/useUpload";
import { isNativeApp } from "@/models/platform";

async function getFileDuration(file: File): Promise<number> {
  try {
    if ((file as any).duration) return (file as any).duration;

    const video = document.createElement("video");
    video.src = URL.createObjectURL(file);
    video.preload = "metadata";
    video.muted = true;
    video.autoplay = false;
    video.playsInline = true;

    return new Promise((resolve) => {
      video.addEventListener("loadedmetadata", () => {
        resolve(video.duration || 0);
      });
      video.addEventListener("error", () => {
        resolve(0);
      });
    });
  } catch (error) {
    return 0;
  }
}

const AlertDashboardStartTranscription = () => {
  const [dismissRequestCount, setDismissRequestCount] = useState(0);
  const { t } = useTranslation();
  const router = useRouter();
  const [loading, setLoading] = useState(false);
  const { isFreeUser, currentAuthUser } = useAuth();

  const currentFile = useUISlice((state) => state.alertStartTranscription);

  const transcriptionOptions = useUserSlice(
    (state) => state.transcriptionOptions
  );
  const currentLanguageIndex = audioLanguages.findIndex(
    (language) => language.value === transcriptionOptions.languageCode
  );

  const {
    uploadFile,
    uploadProgress,
    isUploading,
    setUploadParams,
    cancelUpload,
  } = useUpload({
    maxFileSize: 5 * 1024 * 1024 * 1024, // 5GB
  });

  const _onPressTranscribe = async () => {
    if (!(currentFile instanceof File)) return;

    try {
      setLoading(true);
      const [duration, isFree] = await Promise.all([
        getFileDuration(currentFile),
        isFreeUser(),
      ]);

      let fileId = new Date().getTime().toString();

      const pendingUpload = useUserSlice.getState().uploadPendingFile;
      if (
        currentFile.lastModified === pendingUpload?.file?.lastModified &&
        currentFile.size === pendingUpload?.file?.size &&
        currentFile.name === pendingUpload?.file?.name
      ) {
        fileId = pendingUpload.fileId;
      }

      const createTranscriptionResponse =
        await serverClient.createTranscriptionAsync({
          durationInSeconds: Number(duration.toFixed(2)),
          fileId,
          source: isFree ? "web_guest" : "web_dashboard",
          isRecording: Boolean((currentFile as any).duration),
          fileName: currentFile.name.trim(),
          fileSizeMb: Number((currentFile.size / 1024 / 1024).toFixed(2)),
          fileExtension: currentFile.name.toLowerCase().split(".").pop(),
        });

      if (!createTranscriptionResponse?.data) {
        datadogLogs.logger.error(
          "AlertDashboardStartTranscription: Failed to create transcription",
          { error: createTranscriptionResponse?.error }
        );

        setLoading(false);
        return toast.error(t("alertDashboardStartTranscription.error"));
      }

      const nextTranscription = createTranscriptionResponse.data.transcription;

      if (isNativeApp() || !isFree) {
        try {
          useUserSlice.getState().setUploadPendingFile({
            file: currentFile,
            fileId: nextTranscription.fileId,
          });
          const existingTranscriptions = useUserSlice.getState().transcriptions;
          const transcriptionExists = existingTranscriptions.some(
            (t) => t.fileId === nextTranscription.fileId
          );
          if (!transcriptionExists) {
            useUserSlice
              .getState()
              .setTranscriptions([
                nextTranscription,
                ...existingTranscriptions,
              ]);
          }
        } catch (error) {
          datadogLogs.logger.error(
            "UploadError: Error setting upload pending file",
            error
          );
        }
      }

      setUploadParams({
        fileType: currentFile.type || "application/octet-stream",
        fileId: nextTranscription.fileId,
      });

      const uploadResult = await uploadFile(currentFile);
      if (!uploadResult.success) {
        toast.error(t("alertDashboardStartTranscription.uploadFailed"));
        return setLoading(false);
      }

      const processed = localStorage.getItem("processed");
      if (processed === nextTranscription.fileId) return setLoading(false);

      localStorage.setItem("processed", nextTranscription.fileId);

      const updateResponse = await serverClient.updateTranscriptionAsync({
        type: "OnStatusChange",
        updates: {
          fileId: nextTranscription.fileId,
          deviceId: currentAuthUser().deviceId,
          status: "upload_completed",
        },
      });

      if (updateResponse?.data?.transcription) {
        const updatedTranscription = updateResponse.data.transcription;

        datadogLogs.logger.info("Funnel 3: Upload completed", {
          deviceId: updatedTranscription.deviceId,
          language: updatedTranscription.languageCode,
          fileSize: updatedTranscription.fileSizeMb,
          duration: updatedTranscription.durationInSeconds,
        });

        useUserSlice
          .getState()
          .setTranscriptions([
            updatedTranscription,
            ...useUserSlice.getState().transcriptions,
          ]);

        useUserSlice.getState().setUploadPendingFile(undefined);

        const userAccess = await Paywall.getUserAccess(
          updateResponse.data.transcription.durationInSeconds
        );

        setLoading(false);
        useUISlice.getState().setAlertStartTranscription(undefined);

        if (!userAccess.canTranscribe) {
          return Paywall.showPaywall();
        }

        router.push(
          `${getLocalizedBasePath()}/transcription/${updatedTranscription.id}`
        );
      }
    } catch (error) {
      datadogLogs.logger.error("UploadError: upload failed", {
        deviceId: currentAuthUser().deviceId,
        error,
      });
      setLoading(false);
    }
  };

  const _onDismiss = () => {
    if (isUploading && dismissRequestCount === 0) {
      setDismissRequestCount(dismissRequestCount + 1);

      return toast.loading(
        t("alertDashboardStartTranscription.uploadInProgress"),
        { duration: 3000 }
      );
    }

    if (isUploading) {
      cancelUpload();
      setLoading(false);
      setDismissRequestCount(0);
    }

    useUISlice.getState().setAlertStartTranscription(undefined);
  };

  if (!currentFile) return null;

  const isDisabled = uploadProgress > 0;
  const fileName =
    currentFile instanceof File ? currentFile.name : currentFile.fileName;

  return (
    <ModalContainer
      shouldPersistBackdropClick
      title={fileName}
      onDismiss={_onDismiss}
      hideCancel
      subtitle={t("alertDashboardStartTranscription.subtitle", { fileName })}
      modalMaxWidth={360}
      modalWidth={360}
    >
      <View style={{ width: "100%" }}>
        <View style={{ zIndex: 0, width: "100%", marginTop: 16 }}>
          <PickerSelectLanguage
            disabled={isDisabled}
            onChange={(languageCode) => {
              useUserSlice.getState().setTranscriptionOptions({
                ...transcriptionOptions,
                languageCode,
              });
            }}
            label={t("alertDashboardStartTranscription.spokenLanguage")}
            defaultCurrentIndex={currentLanguageIndex}
          />
        </View>

        <View style={{ marginBottom: 16 }} />

        <Checkbox
          disabled={isDisabled}
          title={t("alertDashboardStartTranscription.speakerRecognition")}
          subtitle={t(
            "alertDashboardStartTranscription.speakerRecognitionSubtitle"
          )}
          value={transcriptionOptions.withSpeakerLabels}
          onCheckboxChange={(value) =>
            useUserSlice.getState().setTranscriptionOptions({
              ...transcriptionOptions,
              withSpeakerLabels: value,
            })
          }
        />

        <View style={{ marginBottom: 24 }} />

        <PrimaryButton
          height={48}
          textStyle={{ fontSize: 18 }}
          loading={(isUploading || loading) && uploadProgress === 0}
          disabled={isDisabled}
          text={
            uploadProgress > 0
              ? t("alertDashboardStartTranscription.uploading", {
                  progress: uploadProgress,
                })
              : t("alertDashboardStartTranscription.startTranscription")
          }
          onPress={_onPressTranscribe}
        />
        <View style={{ marginBottom: -32 }} />
      </View>
    </ModalContainer>
  );
};

export default AlertDashboardStartTranscription;
