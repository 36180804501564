import { useEffect, useState, useRef } from "react";
import { StyleSheet, Text, View } from "@/ui/index";
import RecordRTC from "recordrtc";
import { Colors } from "@/constants";
import Icon from "@/ui/Icon";
import PrimaryButton from "@/ui/PrimaryButton";
import { useUISlice } from "@/models/useUISlice";
import { datadogLogs } from "@datadog/browser-logs";
import { useUserSlice } from "@/models/useUserSlice";
import { useTranslation } from "react-i18next";
import { isNativeApp } from "@/models/platform";
import Paywall from "@/ui/Paywall";

const Recorder = ({
  onRecordingComplete,
  onRecordingStateChange,
}: {
  onRecordingComplete: (file: File, duration: number) => void;
  onRecordingStateChange?: (isRecording: boolean) => void;
}) => {
  const [status, setStatus] = useState<"inactive" | "recording" | "paused">(
    "inactive"
  );
  const [isNative, setIsNative] = useState(false);
  const [isAndroid, setIsAndroid] = useState(false);
  const webRecorderRef = useRef<RecordRTC | null>(null);
  const { t } = useTranslation();
  const startTimeRef = useRef<number | null>(null);
  const durationRef = useRef<number>(0);

  useEffect(() => {
    const initPlatform = async () => {
      if (isNativeApp()) {
        const { Capacitor } = await import("@capacitor/core");
        setIsNative(Capacitor.isNativePlatform());
        setIsAndroid(Capacitor.getPlatform() === "android");
      }
    };
    initPlatform();
  }, []);

  useEffect(() => {
    return () => {
      if (webRecorderRef.current) {
        webRecorderRef.current.destroy();
        webRecorderRef.current = null;
      }
      if (isAndroid) {
        import("@capawesome-team/capacitor-android-foreground-service").then(
          ({ ForegroundService }) => {
            ForegroundService.stopForegroundService();
          }
        );
      }
    };
  }, [isAndroid]);

  useEffect(() => {
    onRecordingStateChange?.(status === "recording");
  }, [status, onRecordingStateChange]);

  const startForegroundService = async () => {
    if (!isAndroid) return;

    const { ForegroundService } = await import(
      "@capawesome-team/capacitor-android-foreground-service"
    );
    await ForegroundService.startForegroundService({
      title: t("recorder.recordingInProgress"),
      body: t("recorder.recordingInBackgroundMessage"),
      id: 12343,
      smallIcon: "ic_stat_notify",
    });
  };

  const checkPermissionAndRecord = async () => {
    try {
      if (isNative) {
        const { VoiceRecorder } = await import("capacitor-voice-recorder");
        // First check if device can record
        const { value: deviceCanRecord } =
          await VoiceRecorder.canDeviceVoiceRecord();
        if (!deviceCanRecord) {
          useUISlice.getState().setAlertModal({
            title: t("recorder.deviceNotSupported"),
            subtitle: t("recorder.deviceNotSupportedMessage"),
            button: {
              text: t("recorder.ok"),
              onPress: () => useUISlice.getState().setAlertModal(undefined),
            },
          });
          return false;
        }

        // Then check and request permission if needed
        const { value: hasPermission } =
          await VoiceRecorder.hasAudioRecordingPermission();
        if (!hasPermission) {
          const { value: permissionGranted } =
            await VoiceRecorder.requestAudioRecordingPermission();
          if (!permissionGranted) {
            useUISlice.getState().setAlertWidgetVisible(undefined);
            useUISlice.getState().setAlertModal({
              title: t("recorder.microphonePermissionRequired"),
              subtitle: t("recorder.microphonePermissionRequiredMessage"),
              button: {
                text: t("recorder.ok"),
                onPress: () => useUISlice.getState().setAlertModal(undefined),
              },
            });
            return null;
          }
        }
        return true;
      } else {
        // Web permission handling
        try {
          const stream = await navigator.mediaDevices.getUserMedia({
            audio: true,
          });
          return stream;
        } catch (error) {
          datadogLogs.logger.error(
            "Recorder: Error getting media stream:",
            error
          );
          return null;
        }
      }
    } catch (error) {
      datadogLogs.logger.error("Recorder: Error checking permission:", error);
      useUISlice.getState().setAlertModal({
        title: t("recorder.microphonePermissionRequired"),
        subtitle: t("recorder.microphonePermissionRequiredMessage"),
        button: {
          text: t("recorder.ok"),
          onPress: () => useUISlice.getState().setAlertModal(undefined),
        },
      });
      return null;
    }
  };

  const startRecording = async () => {
    try {
      const userAccess = await Paywall.getUserAccess();

      if (!userAccess.canTranscribe) {
        useUISlice.getState().setAlertWidgetVisible(undefined);
        return Paywall.showPaywall();
      }

      if (isNative) {
        const hasPermission = await checkPermissionAndRecord();
        if (!hasPermission) return;

        if (isAndroid) {
          await startForegroundService();
        }

        const { VoiceRecorder } = await import("capacitor-voice-recorder");

        const { value: started } = await VoiceRecorder.startRecording();
        if (!started) {
          throw new Error("Failed to start recording");
        }
      } else {
        const stream = await checkPermissionAndRecord();
        if (!stream || !(stream instanceof MediaStream)) return;

        const recorder = new RecordRTC(stream, {
          type: "audio",
          mimeType: "audio/webm",
          checkForInactiveTracks: false,
        });
        recorder.startRecording();
        webRecorderRef.current = recorder;
      }
      startTimeRef.current = Date.now();
      setStatus("recording");
    } catch (error) {
      datadogLogs.logger.error("Recorder: Error starting recording:", error);
      useUISlice.getState().setAlertModal({
        title: t("recorder.error"),
        subtitle: t("recorder.startRecordingError"),
        button: {
          text: t("recorder.ok"),
          onPress: () => useUISlice.getState().setAlertModal(undefined),
        },
      });
    }
  };

  const stopRecording = async () => {
    try {
      if (isNative) {
        const { VoiceRecorder } = await import("capacitor-voice-recorder");

        const result = await VoiceRecorder.stopRecording();
        if (isAndroid) {
          const { ForegroundService } = await import(
            "@capawesome-team/capacitor-android-foreground-service"
          );
          await ForegroundService.stopForegroundService();
        }
        const { value } = result;

        // Convert base64 to blob
        const byteCharacters = atob(value.recordDataBase64);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: "audio/wav" });

        // Create file from blob
        const now = new Date();
        const formattedDate = now.toLocaleString("en-US", {
          month: "short",
          day: "numeric",
          hour: "2-digit",
          minute: "2-digit",
          hour12: false,
        });
        const fileName = `Recording-${formattedDate}.wav`;
        const file = new File([blob], fileName, { type: "audio/wav" });

        if (startTimeRef.current) {
          durationRef.current = Date.now() - startTimeRef.current;
        }
        onRecordingComplete(file, Math.round(durationRef.current / 1000));
      } else if (webRecorderRef.current) {
        webRecorderRef.current.stopRecording(() => {
          const blob = webRecorderRef.current!.getBlob();
          const now = new Date();
          const formattedDate = now.toLocaleString("en-US", {
            month: "short",
            day: "numeric",
            hour: "2-digit",
            minute: "2-digit",
            hour12: false,
          });
          const extension = blob.type.split("/")[1].split(";")[0];
          const fileName = `Recording-${formattedDate}.${extension}`;
          const file = new File([blob], fileName, { type: blob.type });

          if (startTimeRef.current) {
            durationRef.current = Date.now() - startTimeRef.current;
          }
          onRecordingComplete(file, Math.round(durationRef.current / 1000));
          webRecorderRef.current!.destroy();
          webRecorderRef.current = null;
        });
      }
      setStatus("inactive");
      startTimeRef.current = null;
      durationRef.current = 0;

      datadogLogs.logger.info("Recorder: Funnel 2: End recording", {
        deviceId: useUserSlice.getState().deviceId,
        language: useUserSlice.getState().transcriptionOptions?.languageCode,
        duration: Math.round(durationRef.current / 1000),
      });
    } catch (error) {
      datadogLogs.logger.error("Recorder: Error stopping recording:", error);
      useUISlice.getState().setAlertModal({
        title: t("recorder.error"),
        subtitle: t("recorder.stopRecordingError"),
        button: {
          text: t("recorder.ok"),
          onPress: () => useUISlice.getState().setAlertModal(undefined),
        },
      });
    }
  };

  const pauseRecording = async () => {
    try {
      if (isNative) {
        const { VoiceRecorder } = await import("capacitor-voice-recorder");
        const { value: paused } = await VoiceRecorder.pauseRecording();
        if (!paused) {
          throw new Error("Failed to pause recording");
        }
      } else if (webRecorderRef.current) {
        webRecorderRef.current.pauseRecording();
      }
      if (startTimeRef.current) {
        durationRef.current += Date.now() - startTimeRef.current;
        startTimeRef.current = null;
      }
      setStatus("paused");
    } catch (error) {
      datadogLogs.logger.error("Recorder: Error pausing recording:", error);
    }
  };

  const resumeRecording = async () => {
    try {
      if (isNative) {
        const { VoiceRecorder } = await import("capacitor-voice-recorder");
        const { value: resumed } = await VoiceRecorder.resumeRecording();
        if (!resumed) {
          throw new Error("Failed to resume recording");
        }
      } else if (webRecorderRef.current) {
        webRecorderRef.current.resumeRecording();
      }
      startTimeRef.current = Date.now();
      setStatus("recording");
    } catch (error) {
      datadogLogs.logger.error("Recorder: Error resuming recording:", error);
    }
  };

  const _onLargeButtonPress = async () => {
    if (status === "inactive") {
      await startRecording();
    } else if (status === "recording") {
      await stopRecording();
    }
  };

  const _pauseOrPlay = async () => {
    if (status === "inactive") {
      await startRecording();
    } else if (status === "recording") {
      await pauseRecording();
    } else if (status === "paused") {
      await resumeRecording();
    }
  };

  let largeButtonText = t("recorder.startRecording");
  if (status === "recording") largeButtonText = t("recorder.endAndTranscribe");

  return (
    <View style={s.container}>
      <View style={s.labelContainer}>
        {status === "recording" && (
          <Text style={{ fontSize: 18, color: Colors.error }}>
            {t("recorder.recordingInProgress")}
          </Text>
        )}

        {status === "paused" && (
          <Text
            style={{ fontSize: 18, color: Colors.warning, textAlign: "center" }}
          >
            {t("recorder.recordingPaused")}
          </Text>
        )}

        {status === "inactive" && (
          <Text
            style={{ fontSize: 18, color: Colors.text, textAlign: "center" }}
          >
            {t("recorder.startNewRecording")}
          </Text>
        )}
      </View>

      <View style={s.buttonsContainer}>
        {status !== "inactive" && (
          <Icon
            onPress={_pauseOrPlay}
            icon={status === "recording" ? "pause" : "play"}
            style={s.pauseIcon}
            containerStyle={s.pauseButton}
          />
        )}

        <PrimaryButton
          height={48}
          textStyle={{ fontSize: 18 }}
          text={largeButtonText}
          onPress={_onLargeButtonPress}
          style={{
            width: 220,
            backgroundColor:
              status === "recording" ? Colors.error : Colors.accent,
          }}
        />
      </View>
    </View>
  );
};

const s = StyleSheet.create({
  container: {
    width: "100%",
    paddingTop: 32,
    maxWidth: 320,
  },
  labelContainer: { flexDirection: "row", alignSelf: "center" },
  buttonsContainer: {
    marginTop: 32,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
  },
  pauseButton: {
    height: 48,
    width: 48,
    marginRight: 16,
    backgroundColor: Colors.gray4,
  },
  pauseIcon: {
    tintColor: Colors.text,
    width: 18,
    height: 18,
  },
});

export default Recorder;
