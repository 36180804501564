import { TranscriptExportDocument, Transcription, User } from "@/api-lib";
import RecordRTC from "recordrtc";
import { create } from "zustand";

type State = {
  isMobile: boolean;
  width: number;
  recorder?: RecordRTC;
  currentFolder?: User["folders"][number];
  selectedTranscriptions: { [key: string]: Transcription };
  useUploadParams?: {
    fileType: string;
    fileId: string;
  };
  pollingTranscriptionFn: {
    [key: string]: () => void;
  };
  alertPromptSignIn?: {
    title: string;
    subtitle: string;
    placeholder: string;
    shouldPersistBackdropClick?: boolean;
    multiLine?: boolean;
    loading?: boolean;
    hideDismissIcon?: boolean;
    hideCancel?: boolean;
    defaultValue?: string;
    button: {
      text: string;
      onPress: (text: string) => void;
      destructive?: boolean;
    };
    onGoogleSignIn?: () => void;
  };
  alertModal?: {
    title: string;
    loading?: boolean;
    subtitle: string;
    shouldPersistBackdropClick?: boolean;
    hideDismissIcon?: boolean;
    hideCancel?: boolean;
    onDismiss?: () => void;
    onLeftButtonPress?: () => void;
    button?: { text: string; onPress: () => void; destructive?: boolean };
    cancelText?: string;
  };
  alertWidgetVisible?: "recorder" | "paywall";
  alertStartTranscription?: File | Transcription;
  transcriptOptions?: {
    hasSpeakers: boolean;
    onExport: (options: {
      exportType: TranscriptExportDocument;
      withTimestamps: boolean;
      withSpeakers: boolean;
      withMonologue: boolean;
    }) => void;
  };
  alertPrompt?: {
    title: string;
    subtitle?: string;
    placeholder: string;
    shouldPersistBackdropClick?: boolean;
    multiLine?: boolean;
    loading?: boolean;
    hideDismissIcon?: boolean;
    hideCancel?: boolean;
    defaultValue?: string;
    button: {
      text: string;
      onPress: (text: string) => void;
      destructive?: boolean;
    };
  };
};

type Actions = {
  setWidth: (payload: State["width"]) => void;
  setIsMobile: (payload: State["isMobile"]) => void;
  setRecorder: (payload: State["recorder"]) => void;
  setAlertModal: (payload: State["alertModal"]) => void;
  setUseUploadParams: (payload: State["useUploadParams"]) => void;
  setAlertPromptSignIn: (payload: State["alertPromptSignIn"]) => void;
  setSelectedTranscriptions: (payload: State["selectedTranscriptions"]) => void;
  setCurrentFolder: (payload: State["currentFolder"]) => void;
  setTranscriptOptions: (payload: State["transcriptOptions"]) => void;
  setPollingTranscriptionFn: (payload: State["pollingTranscriptionFn"]) => void;
  setAlertPrompt: (payload: State["alertPrompt"]) => void;
  setAlertWidgetVisible: (payload: State["alertWidgetVisible"]) => void;
  setAlertStartTranscription: (
    payload: State["alertStartTranscription"]
  ) => void;
  reset: () => void;
};

// define the initial state
const initialState: State = {
  width: 0,
  isMobile: false,
  selectedTranscriptions: {},
  pollingTranscriptionFn: {},
};

export const useUISlice = create<State & Actions>()((set, get) => ({
  ...initialState,
  setIsMobile: (isMobile) => {
    set({ isMobile });
  },
  setRecorder: (payload) => {
    set({ recorder: payload });
  },
  setUseUploadParams: (payload) => {
    set({ useUploadParams: payload });
  },
  setSelectedTranscriptions: (payload) => {
    set({ selectedTranscriptions: payload });
  },
  setCurrentFolder: (payload) => {
    set({ currentFolder: payload });
  },
  setAlertPromptSignIn: (payload) => {
    set({ alertPromptSignIn: payload });
  },
  setPollingTranscriptionFn: (payload) => {
    set({ pollingTranscriptionFn: payload });
  },
  setWidth: (width) => {
    set({ width });
  },
  setAlertModal: (payload) => {
    set({ alertModal: payload });
  },
  setTranscriptOptions: (payload) => {
    set({ transcriptOptions: payload });
  },
  setAlertPrompt: (payload) => {
    set({ alertPrompt: payload });
  },
  setAlertWidgetVisible: (payload) => {
    set({ alertWidgetVisible: payload });
  },
  setAlertStartTranscription: (payload) => {
    set({ alertStartTranscription: payload });
  },
  reset: () => {
    set({ ...initialState });
  },
}));
