import { hooks } from "@generouted/react-router/client";
import { useCallback } from "react";

export type Path = string;
export type Params = {};
export type ModalPath = never;

export const { useModals, useNavigate, useParams } = hooks<
  Path,
  Params,
  ModalPath
>();

type RouterOptions = {
  shallow?: boolean;
  scroll?: boolean;
};

type PushOptions = RouterOptions & {
  replace?: boolean;
};

export function useRouter() {
  const navigate = useNavigate();

  const push = useCallback(
    (url: string, options: PushOptions = {}) => {
      navigate(url, {
        replace: options.replace,
        preventScrollReset: !options.scroll,
      });
    },
    [navigate]
  );

  const replace = useCallback(
    (url: string, options: RouterOptions = {}) => {
      navigate(url, {
        replace: true,
        preventScrollReset: !options.scroll,
      });
    },
    [navigate]
  );

  const back = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const forward = useCallback(() => {
    navigate(1);
  }, [navigate]);

  return {
    push,
    replace,
    back,
    forward,
    pathname: window.location.pathname,
    query: Object.fromEntries(new URLSearchParams(window.location.search)),
    asPath: window.location.pathname + window.location.search,
  };
}

export const Router = {
  push: (url: string) => window.open(url, "_self"),
  replace: (url: string) => window.open(url, "_self"),
};
