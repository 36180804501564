import { __DEV__ } from "@/constants";
import { isNativeApp } from "@/models/platform";
import * as ServerTypes from "@/api-lib";
const version = "3.7.1";

if (typeof window !== "undefined" && !__DEV__) {
  const env = "prod";
  const isNative = isNativeApp();

  // Dynamically import and initialize Datadog logs
  import("@datadog/browser-logs").then(({ datadogLogs }) => {
    datadogLogs.init({
      clientToken: import.meta.env.VITE_PUBLIC_DATADOG_CLIENT_TOKEN,
      site: "datadoghq.com",
      forwardErrorsToLogs: true,
      service: isNative ? "1transcribe-native" : "1transcribe-web",
      env,
      version,
      sessionSampleRate: 100,
    });
  });

  // Dynamically import and initialize Datadog RUM
  import("@datadog/browser-rum").then(async ({ datadogRum }) => {
    let nativeSessionSampleRate = 0;
    let nativeSessionReplaySampleRate = 0;

    if (isNative) {
      try {
        const config = await fetch("https://api.1transcribe.com", {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ event: { action: "GetNativeRemoteConfigs" } }),
        }).then(
          (res) => res.json() as Promise<ServerTypes.NativeRemoteConfigs>
        );

        nativeSessionSampleRate = config.datadogRum.sessionSampleRate;
        nativeSessionReplaySampleRate =
          config.datadogRum.sessionReplaySampleRate;
      } catch (e) {}
    }

    datadogRum.init({
      applicationId: import.meta.env.VITE_PUBLIC_DATADOG_RUM_APPLICATION_ID,
      clientToken: import.meta.env.VITE_PUBLIC_DATADOG_RUM_CLIENT_TOKEN,
      site: "datadoghq.com",
      service: "1transcribe-rum",
      env,
      version,
      sessionSampleRate: isNative ? nativeSessionSampleRate : 0,
      sessionReplaySampleRate: isNative ? nativeSessionReplaySampleRate : 0,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: "allow",
    });
  });
}
