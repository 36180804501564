import { create } from "zustand";
import { createJSONStorage, persist, StateStorage } from "zustand/middleware";
import { del, get, set } from "idb-keyval";
import {
  Transcript,
  TranscriptEdit,
  Transcription,
  UserAccess,
} from "@/api-lib";
import { audioLanguages, LanguageCode } from "@/constants";

// Add interface for serialized file
interface SerializedFile {
  name: string;
  type: string;
  size: number;
  lastModified: number;
  data: string; // base64 data
  fileId?: string; // Add fileId to serialized file
}

const storage: StateStorage = {
  getItem: async (name: string): Promise<string | null> => {
    return (await get(name)) || null;
  },
  setItem: async (name: string, value: string): Promise<void> => {
    await set(name, value);
  },
  removeItem: async (name: string): Promise<void> => {
    await del(name);
  },
};

// Helper functions for File serialization/deserialization
const serializeFile = async (
  file: File,
  fileId?: string
): Promise<SerializedFile> => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      const base64Data = reader.result as string;
      resolve({
        name: file.name,
        type: file.type,
        size: file.size,
        lastModified: file.lastModified,
        data: base64Data.split(",")[1], // Remove data URL prefix
        fileId, // Include fileId in serialized data
      });
    };
    reader.readAsDataURL(file);
  });
};

const deserializeFile = (
  serializedFile: SerializedFile
): { file: File; fileId?: string } => {
  const binaryString = window.atob(serializedFile.data);
  const bytes = new Uint8Array(binaryString.length);
  for (let i = 0; i < binaryString.length; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  const blob = new Blob([bytes], { type: serializedFile.type });
  const file = new File([blob], serializedFile.name, {
    type: serializedFile.type,
    lastModified: serializedFile.lastModified,
  });
  return {
    file,
    fileId: serializedFile.fileId,
  };
};

type State = {
  _hasHydrated: boolean;
  userToken?: string;
  downloadAppLaunched: boolean;
  timeLeftInHours: number;
  isFree: boolean;
  isUnlimited: boolean;
  userAccess?: UserAccess;
  transcripts: {
    [key: string]: Transcript;
  };
  transcriptsEdits: {
    [key: string]: TranscriptEdit["transcript"];
  };
  transcriptions: Transcription[];
  transcriptionOptions?: Pick<
    Transcription,
    "languageCode" | "withSpeakerLabels" | "numberOfSpeakers"
  >;
  deviceId?: string;
  uploadPendingFile?: { file: File; fileId: string };
  serializedPendingFile?: SerializedFile;
};

type Actions = {
  setUserToken: (token: string) => void;
  setHasHydrated: (status: boolean) => void;
  setTranscripts: (transcripts: State["transcripts"]) => void;
  setUserAccess: (userAccess: UserAccess) => void;
  setTimeLeftInHours: (payload: State["timeLeftInHours"]) => void;
  setIsFree: (isFree: boolean) => void;
  setIsUnlimited: (isUnlimited: boolean) => void;
  setTranscriptionOptions: (options: State["transcriptionOptions"]) => void;
  setTranscriptions: (transcriptions: State["transcriptions"]) => void;
  setTranscriptsEdits: (transcriptsEdits: State["transcriptsEdits"]) => void;
  reset: () => void;
  setDownloadAppLaunched: (downloadAppLaunched: boolean) => void;
  setDeviceId: (deviceId: string) => void;
  setUploadPendingFile: (file: State["uploadPendingFile"]) => void;
};

// define the initial state
let defaultLanguageCode: LanguageCode = "en";
if (typeof navigator !== "undefined") {
  const language = navigator.language.split("-")[0];
  const languageOption = audioLanguages.find(
    (option) => option.value === language
  );
  if (languageOption) {
    defaultLanguageCode = languageOption.value;
  }
}
export const initialState: State = {
  _hasHydrated: false,
  transcripts: {},
  downloadAppLaunched: false,
  timeLeftInHours: 0,
  isFree: true,
  isUnlimited: false,
  transcriptsEdits: {},
  transcriptions: [],
  transcriptionOptions: {
    languageCode: defaultLanguageCode,
    withSpeakerLabels: false,
    numberOfSpeakers: undefined,
  },
  uploadPendingFile: undefined,
};

// create store
export const useUserSlice = create<State & Actions>()(
  persist(
    (set, get) => ({
      ...initialState,
      setTranscripts: (transcripts) => {
        set({ transcripts });
      },
      setUserAccess: (userAccess) => {
        set({ userAccess });
      },
      setTranscriptsEdits: (transcriptsEdits) => {
        set({ transcriptsEdits });
      },
      setHasHydrated: (status) => {
        set({ _hasHydrated: status });
      },
      setUserToken: (token) => {
        set({ userToken: token });
      },
      setTimeLeftInHours: (payload) => {
        set({ timeLeftInHours: payload });
      },
      setIsFree: (isFree) => {
        set({ isFree });
      },
      setIsUnlimited: (isUnlimited) => {
        set({ isUnlimited });
      },
      setDownloadAppLaunched: (downloadAppLaunched) => {
        set({ downloadAppLaunched });
      },
      setTranscriptionOptions: (options) => {
        set({ transcriptionOptions: options });
      },
      setTranscriptions: (transcriptions) => {
        set({ transcriptions });
      },
      reset: () => {
        set({ ...get(), userToken: undefined });
      },
      setDeviceId: (deviceId) => {
        set({ deviceId });
      },
      setUploadPendingFile: async (file) => {
        if (file) {
          const serializedFile = await serializeFile(file.file, file.fileId);
          set({
            uploadPendingFile: { file: file.file, fileId: file.fileId },
            serializedPendingFile: serializedFile,
          });
        } else {
          set({
            uploadPendingFile: undefined,
            serializedPendingFile: undefined,
          });
        }
      },
    }),
    {
      name: "1transcribe-user-storage",
      storage: createJSONStorage(() => storage),
      onRehydrateStorage: () => (state) => {
        if (state) {
          // Deserialize the file if it exists
          if (state.serializedPendingFile) {
            const { file, fileId } = deserializeFile(
              state.serializedPendingFile
            );
            state.uploadPendingFile = {
              file,
              fileId: fileId || "",
            };
          }
          state.setHasHydrated(true);
        }
      },
      partialize: (state) => ({
        ...state,
        uploadPendingFile: undefined,
        serializedPendingFile: state.serializedPendingFile,
      }),
    }
  )
);
