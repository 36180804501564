import "../fetchData/datadog";
import "../locales";
import * as React from "react";
import { Outlet } from "react-router-dom";
import { Colors, DESKTOP_WIDTH } from "@/constants";
import { useUISlice } from "@/models/useUISlice";
import { useUserSlice } from "@/models/useUserSlice";
import AlertModal from "@/ui/AlertModal";
import AlertPrompt from "@/ui/AlertPrompt";
import AlertTranscriptOptions from "@/ui/AlertTranscriptOptions";
import Paywall from "@/ui/Paywall";
import AlertDashboardStartTranscription from "@/ui/AlertDashboardStartTranscription";
import AlertDashboardRecorder from "@/ui/AlertDashboardRecorder";
import AlertPromptSignIn from "@/ui/AlertPromptSignIn";

const HelmetProvider = React.lazy(() =>
  import("react-helmet-async").then((m) => ({ default: m.HelmetProvider }))
);
const GoogleOAuthProvider = React.lazy(() =>
  import("@react-oauth/google").then((m) => ({
    default: m.GoogleOAuthProvider,
  }))
);
const Toaster = React.lazy(() =>
  import("react-hot-toast").then((m) => ({ default: m.Toaster }))
);

// Lazy load analytics
const loadAnalytics = () => {
  import("@/fetchData/gtag").then((gtag) => {
    gtag.pageview(window.location.pathname);
  });
  import("@/fetchData/fpixel").then((fbq) => {
    fbq.pageview();
  });
};

export default function MyApp() {
  const [width, setWidth] = React.useState(0);
  const _hydrated = useUserSlice((state) => state._hasHydrated);

  React.useEffect(() => {
    loadAnalytics();

    const handlePopState = () => {
      loadAnalytics();
    };

    window.addEventListener("popstate", handlePopState);
    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, []);

  React.useEffect(() => {
    setWidth(window.innerWidth);

    if (width > 0) {
      const isMobile = window.innerWidth <= DESKTOP_WIDTH - 1;
      useUISlice.getState().setIsMobile(isMobile);
      useUISlice.getState().setWidth(width);
    }
  }, [width]);

  const isHidden = !width || !_hydrated;

  if (isHidden) {
    return null;
  }

  return (
    <React.Suspense>
      <HelmetProvider>
        <GoogleOAuthProvider
          clientId={import.meta.env.VITE_PUBLIC_GOOGLE_CLIENT_ID}
        >
          <Outlet />
          <AlertModal />
          <AlertPrompt />
          <AlertTranscriptOptions />
          <Paywall />
          <AlertDashboardRecorder />
          <AlertDashboardStartTranscription />
          <AlertPromptSignIn />
          <Toaster
            toastOptions={{
              style: {
                fontFamily: "sans-serif",
                color: Colors.text,
              },
            }}
          />
        </GoogleOAuthProvider>
      </HelmetProvider>
    </React.Suspense>
  );
}
