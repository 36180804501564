import { getLocale } from "@/models/utils";
import i18n from "i18next";
import { Device } from "@capacitor/device";
import { isNativeApp } from "@/models/platform";
import { initReactI18next } from "react-i18next";

import en from "./en.json";
import fr from "./fr.json";
import ar from "./ar.json";
import es from "./es.json";
import it from "./it.json";
import pt from "./pt.json";
import nl from "./nl.json";
import de from "./de.json";

const resources = {
  en: { translation: en },
  fr: { translation: fr },
  ar: { translation: ar },
  es: { translation: es },
  it: { translation: it },
  pt: { translation: pt },
  nl: { translation: nl },
  de: { translation: de },
};

if (!i18n.isInitialized && typeof window === "object") {
  if (isNativeApp()) {
    Device.getLanguageTag().then((languageCode) => {
      i18n.use(initReactI18next).init({
        resources,
        lng: languageCode.value.slice(0, 2),
        fallbackLng: "en",
        compatibilityJSON: "v4",
        interpolation: {
          escapeValue: false,
        },
      });
    });
  } else {
    const deviceLang = getLocale();
    i18n.use(initReactI18next).init({
      resources,
      lng: deviceLang,
      fallbackLng: "en",
      compatibilityJSON: "v4",
      interpolation: {
        escapeValue: false,
      },
    });
  }
}

export { i18n };
