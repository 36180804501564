import React, { useState } from "react";
import { View } from "@/ui/index";
import { useUISlice } from "@/models/useUISlice";
import ModalContainer from "@/ui/ModalContainer";
import Recorder from "@/ui/Recorder";
import { useTranslation } from "react-i18next";

const AlertDashboardRecorder = () => {
  const alertWidgetVisible = useUISlice((state) => state.alertWidgetVisible);
  const { t } = useTranslation();
  const [visible, setVisible] = useState(true);
  const [isRecording, setIsRecording] = useState(false);

  const _onRecordingComplete = (file: File, duration: number) => {
    useUISlice.getState().setAlertWidgetVisible(undefined);
    (file as any).duration = duration;
    useUISlice.getState().setAlertStartTranscription(file);
  };

  const _onDismiss = () => {
    if (!isRecording) {
      return useUISlice.getState().setAlertWidgetVisible(undefined);
    }

    setVisible(false);

    useUISlice.getState().setAlertModal({
      title: t("alertDashboardRecorder.cancelTitle"),
      subtitle: t("alertDashboardRecorder.cancelSubtitle"),
      onDismiss: () => {
        useUISlice.getState().setAlertModal(undefined);
        setVisible(true);
      },
      cancelText: t("alertDashboardRecorder.cancelButton"),
      button: {
        text: t("alertDashboardRecorder.deleteButton"),
        destructive: true,
        onPress: async () => {
          useUISlice.getState().setAlertModal(undefined);
          useUISlice.getState().setAlertWidgetVisible(undefined);
          setVisible(true);
          setIsRecording(false);
        },
      },
    });
  };

  if (alertWidgetVisible !== "recorder") return null;

  return (
    <div style={{ flex: 1, display: visible ? "flex" : "none" }}>
      <ModalContainer
        title={t("alertDashboardRecorder.title")}
        onDismiss={_onDismiss}
        primaryButtonText={t("alertDashboardRecorder.recordButton")}
        shouldPersistBackdropClick
        hideCancel
        modalWidth={360}
      >
        <Recorder
          onRecordingComplete={_onRecordingComplete}
          onRecordingStateChange={(isRecording) => setIsRecording(isRecording)}
        />
        <View style={{ marginBottom: -16 }} />
      </ModalContainer>
    </div>
  );
};

export default AlertDashboardRecorder;
