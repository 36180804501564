import { useUserSlice } from "@/models/useUserSlice";
import { AuthUser } from "@/api-lib";
import { useRouter } from "@/_router";
import { getLocalizedBasePath, parseJwt } from "./utils";
import * as serverClient from "@/models/serverClient";
import toast from "react-hot-toast";
import { useUISlice } from "./useUISlice";
import { useTranslation } from "react-i18next";
import { getPlatform, isNativeApp } from "@/models/platform";
import Paywall from "@/ui/Paywall";

export const useAuth = () => {
  const router = useRouter();
  const { t } = useTranslation();

  const _isValidEmail = (email: string) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email.trim());
  };

  const currentAuthUser = (givenToken?: string): AuthUser | undefined => {
    const userToken = givenToken || useUserSlice.getState().userToken;

    if (!userToken) return undefined;

    return parseJwt(userToken);
  };

  const goToDashboard = async (): Promise<{ success: boolean }> => {
    if (!(await isFreeUser())) {
      router.replace(`${getLocalizedBasePath()}/dashboard`);

      return { success: true };
    }

    return { success: false };
  };

  const signOut = () => {
    router.replace(`${getLocalizedBasePath()}/`);
    useUserSlice.getState().setUserToken(undefined);
    useUserSlice.getState().setIsFree(true);

    setTimeout(() => {
      window.location.reload();
    }, 300);
  };

  const signIn = ({
    forceSignIn = false,
    onGoogleSignIn,
    callback,
  }: {
    forceSignIn?: boolean;
    onGoogleSignIn?: () => void;
    callback?: (authToken: string) => void;
  }) => {
    useUISlice.getState().setAlertPromptSignIn({
      title: t("alertPromptSignIn.title"),
      subtitle: t("alertPromptSignIn.subtitle"),
      placeholder: t("alertPromptSignIn.signInEmailPlaceholder"),
      hideDismissIcon: forceSignIn,
      hideCancel: forceSignIn,
      onGoogleSignIn: onGoogleSignIn
        ? () => {
            onGoogleSignIn();
          }
        : undefined,
      shouldPersistBackdropClick: true,
      button: {
        text: t("auth.signInButton"),
        onPress: async (input) => {
          const email = input.trim().toLowerCase();
          const validEmail = _isValidEmail(email);

          if (!validEmail) {
            return toast.error(t("auth.invalidEmail"));
          }

          useUISlice.getState().setAlertPromptSignIn({
            ...useUISlice.getState().alertPromptSignIn,
            onGoogleSignIn: undefined,
            defaultValue: email,
            loading: true,
          });

          const otpRequestResponse = await serverClient.authenticateAsync({
            type: "OnOneTimeCodeRequested",
            payload: {
              email,
              deviceType: getPlatform(),
              deviceLanguage: navigator.language,
              deviceTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            },
          });

          useUISlice.getState().setAlertPromptSignIn({
            ...useUISlice.getState().alertPromptSignIn,
            onGoogleSignIn: undefined,
            loading: false,
          });

          if (!otpRequestResponse || !otpRequestResponse.data) {
            return toast.error(t("auth.invalidEmail"));
          }

          useUISlice.getState().setAlertPromptSignIn({
            title: t("auth.oneTimeCodeTitle"),
            subtitle: t("auth.oneTimeCodeSubtitle", { email }),
            placeholder: t("auth.oneTimeCodePlaceholder"),
            hideCancel: true,
            hideDismissIcon: forceSignIn,
            shouldPersistBackdropClick: true,
            button: {
              text: t("auth.oneTimeCodeButton"),
              onPress: async (input) => {
                useUISlice.getState().setAlertPromptSignIn({
                  ...useUISlice.getState().alertPromptSignIn,
                  defaultValue: input,
                  loading: true,
                });

                const otpVerifyResponse = await serverClient.authenticateAsync({
                  type: "OnOneTimeCodeSubmit",
                  payload: {
                    email,
                    oneTimeCode: parseInt(input),
                    deviceId: otpRequestResponse.data.deviceId,
                  },
                });

                useUISlice.getState().setAlertPromptSignIn({
                  ...useUISlice.getState().alertPromptSignIn,
                  loading: false,
                });

                if (
                  otpVerifyResponse &&
                  otpVerifyResponse.error === "InvalidOneTimeCode"
                ) {
                  return toast.error(t("auth.invalidCode"));
                }

                if (otpVerifyResponse && otpVerifyResponse.data) {
                  const { accessToken } = otpVerifyResponse.data;

                  useUserSlice.getState().setUserToken(accessToken);

                  const user = currentAuthUser(accessToken);

                  // Dynamically import Datadog modules only when needed
                  const [{ datadogRum }, { datadogLogs }] = await Promise.all([
                    import("@datadog/browser-rum"),
                    import("@datadog/browser-logs"),
                  ]);

                  datadogRum.setUser({ id: user.deviceId, email: user.email });
                  if (isNativeApp()) {
                    const revenuecat = await import("@/models/revenuecat");
                    revenuecat.setEmail(user.email);
                  }

                  datadogLogs.logger.info(
                    "Funnel 0: authenticated with one time code",
                    { user }
                  );

                  callback && callback(accessToken);

                  useUISlice.getState().setAlertPromptSignIn(undefined);
                }
              },
            },
          });
        },
      },
    });
  };

  const isFreeUser = async () => {
    const userAccess = await Paywall.getUserAccess();
    useUserSlice.getState().setIsFree(userAccess.isFreeUser);
    useUserSlice.getState().setIsUnlimited(userAccess.isUnlimited);
    useUserSlice.getState().setUserAccess(userAccess);

    return userAccess.isFreeUser;
  };

  return {
    isFreeUser,
    signIn,
    signOut,
    goToDashboard,
    currentAuthUser,
  };
};
