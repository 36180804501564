import { View, Text, Pressable, StyleSheet, ViewStyle } from "@/ui";
import * as fbq from "../fetchData/fpixel";
import * as gtag from "../fetchData/gtag";
import { useUserSlice } from "@/models/useUserSlice";
import * as serverClient from "@/models/serverClient";
import { datadogLogs } from "@datadog/browser-logs";
import { useTranslation } from "react-i18next";
import { useRouter } from "@/_router";
import { cipher, PurchasePlan, UserAccess } from "@/api-lib";
import { useEffect, useState } from "react";
import { getPlatform, isNativeApp } from "@/models/platform";
import { Colors } from "@/constants";
import Icon from "./Icon";
import ModalContainer from "./ModalContainer";
import { useUISlice } from "@/models/useUISlice";
import PrimaryButton from "./PrimaryButton";
import { useAuth } from "@/models/auth";
import * as RevenueCat from "@/models/revenuecat";
import { StyleProp } from "react-native";

const Paywall = () => {
  const { t } = useTranslation();
  const { currentAuthUser } = useAuth();
  const router = useRouter();
  const [selectedPlan, setSelectedPlan] = useState<PurchasePlan>(
    isNativeApp() ? "10_HOURS_IAP" : "MONTHLY_TOP_TIER"
  );
  const [iapPackages, setIapPackages] = useState<
    RevenueCat.RevenueCatPackage[]
  >([]);
  const [loading, setLoading] = useState(false);
  const [price, setPrice] = useState("");
  const alertWidgetVisible = useUISlice((state) => state.alertWidgetVisible);
  const { goToDashboard } = useAuth();

  useEffect(() => {
    const slug = router.query.checkout_success;

    if (slug === "true") {
      let value = {
        "5_HOURS": 4.99,
        "1_HOUR": 4.99,
        "10_HOURS": 9.99,
        "50_HOURS": 49.99,
        "100_HOURS": 99.99,
        MONTHLY_LOW_TIER: 9.99,
        MONTHLY_MID_TIER: 14.99,
        MONTHLY_TOP_TIER: 19.99,
      }[router.query.plan as PurchasePlan];

      value = value || 4.99;

      fbq.event("Purchase", { currency: "USD", value });
      gtag.conversion({ value, transactionId: String(Date.now()) });

      goToDashboard();
    }
  }, [router.query]);

  useEffect(() => {
    const initRevenueCat = async () => {
      await RevenueCat.initializeRevenueCat();
      const availablePackages = await RevenueCat.getProducts();
      setIapPackages(availablePackages);
    };

    if (isNativeApp()) {
      initRevenueCat();
    }
  }, []);

  useEffect(() => {
    const updatePrice = async () => {
      if (isNativeApp()) {
        const RevenueCat = await import("@/models/revenuecat");
        setPrice(
          RevenueCat.getPriceForPlan(selectedPlan, iapPackages).priceString
        );
      } else {
        setPrice(Paywall.getPrice(selectedPlan));
      }
    };
    updatePrice();
  }, [selectedPlan, iapPackages]);

  const _checkout = async (plan: PurchasePlan) => {
    setLoading(true);
    try {
      if (isNativeApp()) {
        // Use RevenueCat for mobile platforms
        const customerInfo = await RevenueCat.purchasePlan(plan, iapPackages);

        if (customerInfo) {
          useUserSlice.getState().setIsFree(false);

          datadogLogs.logger.info("Funnel 9: RevenueCat Purchase Success", {
            deviceId: useUserSlice.getState().deviceId,
            purchasePlan: plan,
            isNativeApp: true,
          });

          // Track purchase event
          const value = Paywall.getPriceNumber(plan);
          fbq.event("Purchase", { currency: "USD", value });
          gtag.conversion({ value, transactionId: String(Date.now()) });

          useUISlice.getState().setAlertWidgetVisible(undefined);

          // if last transcription was not a full transcript, transcribe it again
          try {
            const purchaseDate = customerInfo.nonSubscriptionTransactions.sort(
              (a, b) =>
                new Date(b.purchaseDate).getTime() -
                new Date(a.purchaseDate).getTime()
            )[0].purchaseDate;

            await serverClient.recordMobilePurchaseAsync({
              purchaseDateAndPlanStringJson: cipher({ purchaseDate, plan }),
              incompleteTranscriptionId: window.location.pathname
                .split("/")
                .pop(),
            });
          } catch (e) {}

          goToDashboard();
        }
      } else {
        // Use web checkout for web platform
        const pathnames = new URL(window.location.href).pathname.split("/");
        let transcriptionId = pathnames.pop();

        const transcription = useUserSlice
          .getState()
          .transcriptions.find((t) => t.id === transcriptionId);

        if (transcription && transcription.isFullTranscript) {
          transcriptionId = undefined;
        }

        const response = await serverClient.getPaymentLinkAsync({
          baseAppUrl: window.location.href,
          purchasePlan: plan,
          transcriptionId,
        });

        if (response && response.data) {
          datadogLogs.logger.info("Funnel 9: Web Checkout", {
            deviceId: useUserSlice.getState().deviceId,
            purchasePlan: plan,
          });

          window.location.href = response.data.url;
        }
      }
    } catch (error) {
      console.error("Purchase error:", error);
      // Handle error (show error message to user)
    } finally {
      setLoading(false);
    }
  };

  if (alertWidgetVisible !== "paywall") return null;

  return (
    <ModalContainer
      title={t("paywall.title")}
      loading={loading}
      subtitle={t("paywall.subtitle")}
      onDismiss={() => {
        useUISlice.getState().setAlertWidgetVisible(undefined);
      }}
      shouldPersistBackdropClick={true}
      primaryButtonText={t("paywall.upgrade")}
      hideCancel={true}
      modalWidth={360}
    >
      <View style={{ height: 16 }} />

      {!isNativeApp() && (
        <>
          <Paywall.Item
            title={t("paywall.fiveHours")}
            description={t("paywall.priceSubtitle", {
              price: Paywall.getPrice("5_HOURS"),
            })}
            onPress={() => setSelectedPlan("5_HOURS")}
            isSelected={selectedPlan === "5_HOURS"}
            style={{ marginBottom: 16 }}
          />

          <Paywall.Item
            title={t("paywall.unlimited")}
            description={t("paywall.unlimitedSubtitle", {
              price: `${Paywall.getPrice("MONTHLY_TOP_TIER")}`,
            })}
            onPress={() => setSelectedPlan("MONTHLY_TOP_TIER")}
            isSelected={selectedPlan === "MONTHLY_TOP_TIER"}
            style={{ marginBottom: 16 }}
          />
        </>
      )}

      {iapPackages.length >= 3 && (
        <>
          <Paywall.Item
            title={t("paywall.oneHour")}
            description={t("paywall.perHour", {
              price: RevenueCat.getPriceForPlan("1_HOUR_IAP", iapPackages)
                .pricePerHour,
            })}
            onPress={() => setSelectedPlan("1_HOUR_IAP")}
            isSelected={selectedPlan === "1_HOUR_IAP"}
            style={{ marginBottom: 16 }}
          />

          <Paywall.Item
            title={t("paywall.tenHours")}
            description={t("paywall.perHour", {
              price: RevenueCat.getPriceForPlan("10_HOURS_IAP", iapPackages)
                .pricePerHour,
            })}
            discount={{
              text: t("paywall.discountSubtitle", {
                amountSaved: RevenueCat.getPriceForPlan(
                  "10_HOURS_IAP",
                  iapPackages
                ).amountSaved,
              }),
              color: Colors.warning,
            }}
            onPress={() => setSelectedPlan("10_HOURS_IAP")}
            isSelected={selectedPlan === "10_HOURS_IAP"}
            style={{ marginBottom: 16 }}
          />
        </>
      )}

      <View style={{ height: 8 }} />

      <PrimaryButton
        text={t("paywall.pay", { price })}
        height={56}
        textStyle={{ fontSize: 18 }}
        onPress={() => _checkout(selectedPlan)}
      />

      <View style={{ marginBottom: -24 }} />
    </ModalContainer>
  );
};

Paywall.Item = ({
  title,
  description,
  onPress,
  isSelected,
  discount,
  style,
}: {
  title: string;
  description: string;
  onPress: () => void;
  isSelected: boolean;
  style?: StyleProp<ViewStyle>;
  discount?: {
    text: string;
    color: string;
  };
}) => {
  return (
    <Pressable
      onPress={onPress}
      style={{
        ...s.planContainer,
        borderColor: isSelected ? Colors.accent : Colors.gray4,
        ...(style && (style as any)),
      }}
    >
      <View style={{ flex: 1 }}>
        <View style={{ flexDirection: "row", alignItems: "center" }}>
          <Text style={s.planTitle}>{title}</Text>

          {discount && (
            <View
              style={{
                marginLeft: 8,
                paddingHorizontal: 6,
                paddingVertical: 2,
                borderRadius: 4,
                backgroundColor: discount?.color,
              }}
            >
              <Text style={{ color: Colors.white, fontWeight: "600" }}>
                {discount?.text}
              </Text>
            </View>
          )}
        </View>

        <Text style={s.planDescription}>{description}</Text>
      </View>

      <View
        style={{
          ...s.checkbox,
          backgroundColor: isSelected ? Colors.accent : Colors.white,
          borderColor: isSelected ? Colors.accent : Colors.gray4,
        }}
      >
        <Icon icon="tick" style={s.checkboxIcon} />
      </View>
    </Pressable>
  );
};

const s = StyleSheet.create({
  checkbox: {
    width: 18,
    height: 18,
    borderWidth: 1.5,
    borderRadius: 4,
    alignItems: "center",
    justifyContent: "center",
    borderColor: Colors.subtitle,
  },
  checkboxIcon: {
    tintColor: Colors.white,
    width: 12,
    height: 12,
  },
  planContainer: {
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "space-between",
    borderWidth: 1.5,
    borderRadius: 6,
    padding: 12,
  },
  planTitle: {
    fontWeight: "600",
    fontSize: 18,
    lineHeight: 24,
    color: Colors.text,
  },
  planPrice: {
    fontSize: 14,
    color: Colors.text,
    lineHeight: 22,
  },
  planDescription: {
    fontSize: 14,
    color: Colors.subtitle,
    lineHeight: 22,
  },
});

Paywall.getPrice = (plan: PurchasePlan): string => {
  const prices: { [key in PurchasePlan]: string } = {
    "5_HOURS": "$4.99",
    "1_HOUR": "$4.99",
    "10_HOURS": "$9.99",
    "50_HOURS": "$49.99",
    "100_HOURS": "$99.99",
    "1_HOUR_IAP": "",
    "10_HOURS_IAP": "",
    "10_HOURS_IAP_OLD": "",
    "100_HOURS_IAP": "",
    MONTHLY_LOW_TIER: "$9.99",
    MONTHLY_MID_TIER: "$14.99",
    MONTHLY_TOP_TIER: "$19.99",
  };

  const price = prices[plan];

  return price;
};

Paywall.getSubscriptionPlanByCountry = (country = "US"): PurchasePlan => {
  return "MONTHLY_LOW_TIER";
};

Paywall.showPaywall = () => {
  useUISlice.getState().setAlertWidgetVisible("paywall");
};

Paywall.getCheckoutUrl = async (plan: PurchasePlan) => {
  // Use web checkout for web platform
  const pathnames = new URL(window.location.href).pathname.split("/");
  let transcriptionId = pathnames.pop();

  const transcription = useUserSlice
    .getState()
    .transcriptions.find((t) => t.id === transcriptionId);

  if (transcription && transcription.isFullTranscript) {
    transcriptionId = undefined;
  }

  const response = await serverClient.getPaymentLinkAsync({
    baseAppUrl: window.location.href,
    purchasePlan: plan,
    transcriptionId,
  });

  if (response && response.data) {
    datadogLogs.logger.info("Funnel 9: Web Checkout", {
      deviceId: useUserSlice.getState().deviceId,
      purchasePlan: plan,
    });

    window.location.href = response.data.url;
  }
};

Paywall.getUserAccess = async (
  durationInSeconds?: number
): Promise<UserAccess> => {
  let purchasesIds: Record<string, string> = undefined;

  if (isNativeApp()) {
    const revenuecat = await import("@/models/revenuecat");
    purchasesIds = await revenuecat.getPurchasesIds();

    // alert(Object.keys(purchasesIds).length);
    // Object.entries(purchasesIds)
    //   .slice(0, 21)
    //   .forEach(([key, value]) => {
    //     delete purchasesIds[key];
    //   });
  }

  const userAccessResponse = await serverClient.getUserAccessAsync({
    deviceType: getPlatform(),
    durationInSeconds,
    androidOrIosPurchases: purchasesIds,
  });

  if (userAccessResponse?.data) return userAccessResponse.data;

  return {
    isUnlimited: false,
    canTranscribe: true,
    isFreeUser: true,
    remainingTimeInSeconds: 0,
  };
};

Paywall.getPriceNumber = (plan: PurchasePlan): number => {
  const prices: { [key in PurchasePlan]: number } = {
    "1_HOUR": 4.99,
    "5_HOURS": 4.99,
    "50_HOURS": 49.99,
    "100_HOURS": 99.99,
    "10_HOURS": 9.99,
    "1_HOUR_IAP": 4.99,
    "10_HOURS_IAP": 19.99,
    "10_HOURS_IAP_OLD": 29.99,
    "100_HOURS_IAP": 99.99,
    MONTHLY_LOW_TIER: 9.99,
    MONTHLY_MID_TIER: 19.99,
    MONTHLY_TOP_TIER: 29.99,
  };

  return prices[plan];
};

export default Paywall;
