import { useState, useCallback, useRef } from "react";
import { datadogLogs } from "@datadog/browser-logs";
import { useUISlice } from "./useUISlice";
import * as serverClient from "@/models/serverClient";

interface UploadParams {
  fileType: string;
  fileId: string;
}

const DIRECT_UPLOAD_LIMIT = 1024 * 1024 * 5; // 5 MB
const MAX_RETRIES = 3;
const INITIAL_RETRY_DELAY = 1000; // 1 second

const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

const withRetry = async <T,>(
  operation: () => Promise<T>,
  retryCount = 0
): Promise<T> => {
  try {
    return await operation();
  } catch (error) {
    if (error.name === "AbortError") {
      throw error;
    }

    if (retryCount >= MAX_RETRIES) {
      throw error;
    }

    const delay = INITIAL_RETRY_DELAY * Math.pow(2, retryCount);
    await sleep(delay);
    return withRetry(operation, retryCount + 1);
  }
};

export const useUpload = ({ maxFileSize }: { maxFileSize?: number }) => {
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const abortControllerRef = useRef<AbortController | null>(null);

  const setUploadParams = useCallback((params: UploadParams) => {
    useUISlice.getState().setUseUploadParams(params);
  }, []);

  const multipartUpload = async (file: File): Promise<{ success: boolean }> => {
    try {
      setIsUploading(true);
      const arrayBuffer = await file.arrayBuffer();
      const chunkSize = 1024 * 1024 * 5; // 5 MB chunks
      const totalParts = Math.ceil(arrayBuffer.byteLength / chunkSize);
      const completedParts: { PartNumber: number; ETag: string }[] = [];

      // Initialize multipart upload with retry
      const initResponse = await withRetry(() =>
        serverClient.uploadFileAsync({
          fileId: useUISlice.getState().useUploadParams.fileId,
          fileExtension: file.name.split(".").pop()?.toLowerCase() || "",
          partNumber: "1",
          signal: abortControllerRef?.current?.signal,
        })
      );

      if (!initResponse.ok || !initResponse.uploadId) {
        datadogLogs.logger.error(
          "UploadError: Failed to initialize multipart upload",
          { error: initResponse.error }
        );
        return { success: false };
      }

      // Upload all parts including the first one
      for (let i = 0; i < arrayBuffer.byteLength; i += chunkSize) {
        const chunk = arrayBuffer.slice(i, i + chunkSize);
        const partNumber = Math.floor(i / chunkSize) + 1;
        const isLastChunk = i + chunkSize >= arrayBuffer.byteLength;

        const response = await withRetry(() =>
          serverClient.uploadFileAsync({
            body: chunk,
            partNumber: partNumber.toString(),
            uploadId: initResponse.uploadId,
            parts: isLastChunk ? completedParts : undefined,
            fileId: useUISlice.getState().useUploadParams.fileId,
            fileExtension: file.name.split(".").pop()?.toLowerCase() || "",
            signal: abortControllerRef?.current?.signal,
          })
        );

        if (!response.ok) {
          datadogLogs.logger.error(
            `UploadError: Failed to upload part ${partNumber}`,
            { error: response.error }
          );
          return { success: false };
        }

        if (response.etag) {
          completedParts.push({ PartNumber: partNumber, ETag: response.etag });
        }

        if (response.completed) {
          datadogLogs.logger.info("Multipart upload completed successfully", {
            fileSize: `${file.size / 1024 / 1024} MB`,
            fileType: file.type,
            parts: totalParts,
          });
          setIsUploading(false);
          setUploadProgress(0);
          return { success: true };
        }

        setUploadProgress(Math.round((partNumber / totalParts) * 100));
      }

      datadogLogs.logger.info("Multipart upload completed", {
        fileSize: `${file.size / 1024 / 1024} MB`,
        fileType: file.type,
        parts: totalParts,
      });

      setIsUploading(false);
      setUploadProgress(0);
      return { success: true };
    } catch (error) {
      if (error.name !== "AbortError") {
        datadogLogs.logger.error("UploadError: Multipart upload failed", {
          error: error?.message,
          retries: MAX_RETRIES,
        });
      }
      setIsUploading(false);
      setUploadProgress(0);
      return { success: false };
    }
  };

  const directUpload = async (file: File): Promise<{ success: boolean }> => {
    try {
      setIsUploading(true);
      const arrayBuffer = await file.arrayBuffer();

      const response = await withRetry(() =>
        serverClient.uploadFileAsync({
          body: arrayBuffer,
          fileId: useUISlice.getState().useUploadParams.fileId,
          fileExtension: file.name.split(".").pop()?.toLowerCase() || "",
          signal: abortControllerRef?.current?.signal,
        })
      );

      if (!response.ok) {
        datadogLogs.logger.error("UploadError: Failed to upload file", {
          error: response.error,
        });
        return { success: false };
      }

      datadogLogs.logger.info("Direct upload completed", {
        fileSize: `${file.size / 1024 / 1024} MB`,
        fileType: file.type,
      });

      setIsUploading(false);
      setUploadProgress(0);
      return { success: true };
    } catch (error) {
      if (error.name !== "AbortError") {
        datadogLogs.logger.error("UploadError: Direct upload failed", {
          error: error?.message,
          retries: MAX_RETRIES,
        });
      }
      setIsUploading(false);
      setUploadProgress(0);
      return { success: false };
    }
  };

  const uploadFile = async (file: File): Promise<{ success: boolean }> => {
    if (!file) return { success: false };

    if (maxFileSize && file.size > maxFileSize) {
      datadogLogs.logger.error(
        "UploadError: File size exceeds maximum allowed",
        {
          fileSize: `${file.size / 1024 / 1024} MB`,
          fileType: file.type,
          maxFileSize: `${maxFileSize / 1024 / 1024} MB`,
        }
      );
      return { success: false };
    }

    // Use multipart upload for larger files
    if (file.size > DIRECT_UPLOAD_LIMIT) {
      return multipartUpload(file);
    }

    // Use simple upload for small files
    return directUpload(file);
  };

  const cancelUpload = useCallback(() => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
      abortControllerRef.current = null;
    }

    setIsUploading(false);
    setUploadProgress(0);
  }, []);

  return {
    uploadFile,
    uploadProgress,
    isUploading,
    setUploadParams,
    cancelUpload,
  };
};
