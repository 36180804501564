import { Suspense, useEffect } from "react";
import { Routes } from "@generouted/react-router/lazy";
import { createRoot } from "react-dom/client";
import { isNativeApp } from "@/models/platform";

const App = () => {
  useEffect(() => {
    const initNativeFeatures = async () => {
      if (isNativeApp()) {
        try {
          const [RevenueCat, LiveUpdate] = await Promise.all([
            import("@/models/revenuecat"),
            import("@/models/liveUpdate"),
          ]);

          await RevenueCat.initializeRevenueCat();
          await LiveUpdate.isReadyAsync();
          await LiveUpdate.downloadBundleAsync();
        } catch (error) {
          console.error("Failed to initialize native features:", error);
        }
      }
    };

    initNativeFeatures();
  }, []);

  return (
    <Suspense>
      <Routes />
    </Suspense>
  );
};

createRoot(document.getElementById("root")!).render(<App />);
