import {
  CustomerInfo,
  Purchases,
  PurchasesConfiguration,
  PurchasesPackage,
} from "@revenuecat/purchases-capacitor";
import { getPlatform, isNativeApp } from "@/models/platform";
import { PurchasePlan } from "@/api-lib";
import { getDeviceIdAsync } from "@/models/serverClient";

// Replace these with your actual RevenueCat API keys
const REVENUECAT_API_KEY_ANDROID = "goog_YeeSXLMOrAUFGFkjvsGarFnXCMd";
const REVENUECAT_API_KEY_IOS = "appl_JeRKDZSUzpIYhskHqnqdhhcXyOL";

export type RevenueCatPackage = PurchasesPackage;

const PRODUCT_IDENTIFIERS: Record<PurchasePlan, string> = {
  "1_HOUR_IAP": "com.dktlabs.anytranscribe.iap499",
  "10_HOURS_IAP": "com.dktlabs.anytranscribe.iap1999",
  "10_HOURS_IAP_OLD": "com.dktlabs.anytranscribe.iap2999 ",
  "100_HOURS_IAP": "com.dktlabs.anytranscribe.iap9999",
  MONTHLY_LOW_TIER: "",
  MONTHLY_MID_TIER: "",
  MONTHLY_TOP_TIER: "",
  "1_HOUR": "",
  "10_HOURS": "",
  "5_HOURS": "",
  "50_HOURS": "",
  "100_HOURS": "",
};

const getPurchasePlanFromProductIdentifier = (
  productIdentifier: string
): PurchasePlan | undefined => {
  return Object.keys(PRODUCT_IDENTIFIERS).find(
    (key) => PRODUCT_IDENTIFIERS[key] === productIdentifier
  ) as PurchasePlan | undefined;
};

const formatPrice = (price: number, template: string) => {
  // Extract the currency format from the template by replacing the number with a placeholder
  const currencyFormat = template.replace(/\d+(?:[,.]\d+)?/, "{price}");
  // Replace the placeholder with the actual price
  return currencyFormat.replace("{price}", price.toString());
};

export const getPriceForPlan = (
  plan: PurchasePlan,
  packages: RevenueCatPackage[]
) => {
  try {
    const pkg = packages.find(
      (pkg) => pkg.product.identifier === PRODUCT_IDENTIFIERS[plan]
    );
    const priceOneHour = packages.find(
      (pkg) => pkg.product.identifier === PRODUCT_IDENTIFIERS["1_HOUR_IAP"]
    ).product.price;

    const price = pkg?.product.price;

    const potentialDiscountMap = {
      "1_HOUR_IAP": 0,
      "10_HOURS_IAP": priceOneHour * 10 - price,
      "100_HOURS_IAP": priceOneHour * 100 - price,
    };

    const pricePerHourMap = {
      "1_HOUR_IAP": price / 1,
      "10_HOURS_IAP": price / 10,
      "100_HOURS_IAP": price / 100,
    };

    const priceString = pkg?.product.priceString;

    return {
      priceString,
      price: pkg?.product.price,
      pricePerHour: formatPrice(
        Number(pricePerHourMap[plan].toFixed(2)),
        priceString
      ),
      amountSaved: formatPrice(
        Number(potentialDiscountMap[plan].toFixed(0)),
        priceString
      ),
    };
  } catch (e) {
    console.error(e);

    return {
      priceString: "",
      price: 0,
      pricePerHour: "",
      amountSaved: "",
    };
  }
};

export const setEmail = async (email: string) => {
  if (!isNativeApp()) return;

  try {
    await Purchases.setEmail({ email });
  } catch (error) {
    console.error("Error setting email:", error);
  }
};

export const getTransactions = async (): Promise<
  Record<string, PurchasePlan>
> => {
  const { customerInfo } = await Purchases.getCustomerInfo();

  return customerInfo.nonSubscriptionTransactions.reduce(
    (acc, t) => ({
      ...acc,
      [t.purchaseDate]: getPurchasePlanFromProductIdentifier(
        t.productIdentifier
      ),
    }),
    {}
  );
};

export const getCustomerInfo = async (): Promise<CustomerInfo> => {
  const { customerInfo } = await Purchases.getCustomerInfo();

  return customerInfo;
};

export const getPurchasesIds = async (): Promise<Record<string, string>> => {
  const { customerInfo } = await Purchases.getCustomerInfo();

  return customerInfo.nonSubscriptionTransactions.reduce(
    (acc, t) => ({
      ...acc,
      [t.purchaseDate]: t.productIdentifier,
    }),
    {}
  );
};

export const initializeRevenueCat = async () => {
  const deviceId = await getDeviceIdAsync();

  const configuration: PurchasesConfiguration = {
    apiKey:
      getPlatform() === "ios"
        ? REVENUECAT_API_KEY_IOS
        : REVENUECAT_API_KEY_ANDROID,
    appUserID: deviceId,
  };

  await Purchases.configure(configuration);
};

export const getProducts = async () => {
  try {
    const offerings = await Purchases.getOfferings();
    console.log("offerings", { pkgs: offerings.all["v2"]?.availablePackages });
    return offerings.all["v2"]?.availablePackages || [];
  } catch (error) {
    console.error("Error fetching products:", error);
    return [];
  }
};

export const purchasePlan = async (
  plan: PurchasePlan,
  packages: RevenueCatPackage[]
): Promise<CustomerInfo> => {
  try {
    const pkg = packages.find(
      (pkg) => pkg.product.identifier === PRODUCT_IDENTIFIERS[plan]
    );

    if (!pkg) {
      throw new Error("Package not found");
    }

    const { customerInfo } = await Purchases.purchasePackage({
      aPackage: pkg,
    });
    return customerInfo;
  } catch (error) {
    console.error("Error making purchase:", error);
    throw error;
  }
};

export const restorePurchases = async () => {
  try {
    const { customerInfo } = await Purchases.restorePurchases();
    return customerInfo;
  } catch (error) {
    console.error("Error restoring purchases:", error);
    throw error;
  }
};

export const getCurrentPurchaserInfo = async () => {
  try {
    const { customerInfo } = await Purchases.getCustomerInfo();
    return customerInfo;
  } catch (error) {
    console.error("Error getting purchaser info:", error);
    throw error;
  }
};
