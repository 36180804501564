import * as ServerTypes from "@/api-lib";
import { useUserSlice } from "./useUserSlice";
import { Router } from "@/_router";
import FingerprintJS from "@fingerprintjs/fingerprintjs";
import { Device } from "@capacitor/device";
import { __DEV__ } from "@/constants";
import { getLocalizedBasePath, parseJwt } from "./utils";
import { getPlatform, isNativeApp } from "@/models/platform";

let serverUrl = "https://api.1transcribe.com";

if (__DEV__) {
  serverUrl = "http://localhost:8787";
  // serverUrl = "https://1transcribe-api-test.dkt201474-com.workers.dev";
}

export const cipher = (jsonData: { [key: string]: any }): string => {
  const hexBuff = Buffer.from(JSON.stringify(jsonData));
  const hexData = hexBuff.toString("hex");

  const base64Buff = Buffer.from(hexData);
  return base64Buff.toString("base64");
};

const getTokenAsync = async (): Promise<string> => {
  let token = useUserSlice.getState().userToken;

  if (token) {
    const authUser = parseJwt(token);
    const expirationTime = authUser?.exp ? authUser.exp * 1000 : 0; // Convert to milliseconds
    const isExpired = Date.now() >= expirationTime;

    if (!isExpired) {
      return token;
    }
  }

  const dId = await getDeviceIdAsync();
  const authResponse = await authenticateAsync({
    type: "OnGuessSignIn",
    payload: {
      accessToken: cipher({ deviceId: dId }),
      deviceId: dId,
      deviceLanguage: navigator.language,
      deviceTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    },
  });

  if (authResponse && authResponse.data) {
    const { accessToken } = authResponse.data;
    useUserSlice.getState().setUserToken(accessToken);

    const authUser = parseJwt(accessToken);

    // Dynamically import Datadog modules only when needed
    const [{ datadogRum }, { datadogLogs }] = await Promise.all([
      import("@datadog/browser-rum"),
      import("@datadog/browser-logs"),
    ]);

    datadogRum.setUser({ id: authUser?.deviceId, email: authUser?.email });

    datadogLogs.logger.info("Funnel 0: authenticated as guest", {
      user: authUser,
    });

    token = accessToken;
  }

  return token;
};

export const getDeviceIdAsync = async () => {
  if (isNativeApp() && getPlatform() === "android") {
    const { identifier } = await Device.getId();
    return `android_${identifier}`;
  }

  if (typeof window !== "object") return;

  const fp = await FingerprintJS.load();
  const { visitorId } = await fp.get();

  let prefix = "web_";

  if (isNativeApp()) {
    prefix = `${getPlatform()}_`.toLowerCase();
  }

  return `${prefix}${visitorId}`;
};

const useAuthorized = (res: Response): any => {
  if (res.status === 403) {
    Router.replace(`${getLocalizedBasePath()}/`);
    useUserSlice.getState().setUserToken(undefined);

    setTimeout(() => {
      window.location.reload();
    }, 300);

    return res.json();
  }

  return res.json();
};

export const createTranscriptionAsync = async (
  params: ServerTypes.ApiRequestCreateTranscription["payload"]
): Promise<ServerTypes.ApiResponseCreateTranscription> => {
  try {
    const requestPayload: ServerTypes.ApiRequest = {
      action: "CreateTranscription",
      payload: params,
    };

    return await fetch(serverUrl, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: await getTokenAsync(),
      },
      body: JSON.stringify({ event: requestPayload }),
    }).then((res) => useAuthorized(res));
  } catch (e) {
    __DEV__ && console.log(e);
  }
};

export const updateTranscriptionAsync = async (
  params: ServerTypes.ApiRequestUpdateTranscription["payload"]
): Promise<ServerTypes.ApiResponseUpdateTranscription> => {
  try {
    const requestPayload: ServerTypes.ApiRequest = {
      action: "UpdateTranscription",
      payload: params,
    };

    return await fetch(serverUrl, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: await getTokenAsync(),
      },
      body: JSON.stringify({ event: requestPayload }),
    }).then((res) => useAuthorized(res));
  } catch (e) {
    __DEV__ && console.log(e);
  }
};

export const startTranscriptionV2Async = async (
  params: ServerTypes.ApiRequestStartTranscriptionV2["payload"]
): Promise<ServerTypes.ApiResponseStartTranscriptionV2> => {
  try {
    const requestPayload: ServerTypes.ApiRequest = {
      action: "StartTranscriptionV2",
      payload: params,
    };

    return await fetch(serverUrl, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: await getTokenAsync(),
      },
      body: JSON.stringify({ event: requestPayload }),
    }).then((res) => useAuthorized(res));
  } catch (e) {
    __DEV__ && console.log(e);
  }
};

export const listTranscriptionsAsync = async (
  includePending = true
): Promise<ServerTypes.ApiResponseListTranscriptions> => {
  try {
    const requestPayload: ServerTypes.ApiRequest = {
      action: "ListTranscriptions",
      includePending,
    };

    return await fetch(serverUrl, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: await getTokenAsync(),
      },
      body: JSON.stringify({ event: requestPayload }),
    }).then((res) => useAuthorized(res));
  } catch (e) {
    __DEV__ && console.log(e);
  }
};

export const getTranscriptionAsync = async (
  params: ServerTypes.ApiRequestGetTranscription["payload"]
): Promise<ServerTypes.ApiResponseGetTranscription> => {
  try {
    const requestPayload: ServerTypes.ApiRequest = {
      action: "GetTranscription",
      payload: params,
    };

    return await fetch(serverUrl, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: await getTokenAsync(),
      },
      body: JSON.stringify({ event: requestPayload }),
    }).then((res) => useAuthorized(res));
  } catch (e) {
    __DEV__ && console.log(e);
  }
};

export const getTranscriptEditAsync = async (
  params: ServerTypes.ApiRequestGetTranscriptEdit["payload"]
): Promise<ServerTypes.ApiResponseGetTranscriptEdit> => {
  try {
    const requestPayload: ServerTypes.ApiRequest = {
      action: "GetTranscriptEdit",
      payload: params,
    };

    return await fetch(serverUrl, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: await getTokenAsync(),
      },
      body: JSON.stringify({ event: requestPayload }),
    }).then((res) => useAuthorized(res));
  } catch (e) {
    __DEV__ && console.log(e);
  }
};

export const editTranscriptAsync = async (
  params: ServerTypes.ApiRequestEditTranscript["payload"]
): Promise<ServerTypes.ApiResponseEditTranscript> => {
  try {
    const requestPayload: ServerTypes.ApiRequest = {
      action: "EditTranscript",
      payload: params,
    };

    return await fetch(serverUrl, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: await getTokenAsync(),
      },
      body: JSON.stringify({ event: requestPayload }),
    }).then((res) => useAuthorized(res));
  } catch (e) {
    __DEV__ && console.log(e);
  }
};

export const deleteTranscriptionAsync = async (
  params: ServerTypes.ApiRequestDeleteTranscription["payload"]
): Promise<ServerTypes.ApiResponseDeleteTranscription> => {
  try {
    const requestPayload: ServerTypes.ApiRequest = {
      action: "DeleteTranscription",
      payload: params,
    };

    return await fetch(serverUrl, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: await getTokenAsync(),
      },
      body: JSON.stringify({ event: requestPayload }),
    }).then((res) => useAuthorized(res));
  } catch (e) {
    __DEV__ && console.log(e);
  }
};

export const getPaymentLinkAsync = async (
  params: ServerTypes.ApiRequestCheckout["payload"]
): Promise<ServerTypes.ApiResponseCheckout> => {
  try {
    const requestPayload: ServerTypes.ApiRequest = {
      action: "Checkout",
      payload: params,
    };

    return await fetch(serverUrl, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: await getTokenAsync(),
      },
      body: JSON.stringify({ event: requestPayload }),
    }).then((res) => useAuthorized(res));
  } catch (e) {
    __DEV__ && console.log(e);
  }
};

export const sendInquiryAsync = async (
  params: ServerTypes.ApiRequestSendInquiry["payload"]
): Promise<ServerTypes.ApiResponseSendInquiry> => {
  try {
    const requestPayload: ServerTypes.ApiRequest = {
      action: "SendInquiry",
      payload: params,
    };

    return await fetch(serverUrl, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: await getTokenAsync(),
      },
      body: JSON.stringify({ event: requestPayload }),
    }).then((res) => useAuthorized(res));
  } catch (e) {
    __DEV__ && console.log(e);
  }
};

export const updateUserAsync = async (
  params: ServerTypes.ApiRequestUpdateUser["payload"]
): Promise<ServerTypes.ApiResponseUpdateUser> => {
  try {
    const requestPayload: ServerTypes.ApiRequest = {
      action: "UpdateUser",
      payload: params,
    };

    return await fetch(serverUrl, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: await getTokenAsync(),
      },
      body: JSON.stringify({ event: requestPayload }),
    }).then((res) => useAuthorized(res));
  } catch (e) {
    __DEV__ && console.log(e);
  }
};

export const getWebUserStatusAsync =
  async (): Promise<ServerTypes.ApiResponseGetWebUserStatus> => {
    try {
      const requestPayload: ServerTypes.ApiRequest = {
        action: "GetWebUserStatus",
      };

      return await fetch(serverUrl, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: await getTokenAsync(),
        },
        body: JSON.stringify({ event: requestPayload }),
      }).then((res) => useAuthorized(res));
    } catch (e) {
      __DEV__ && console.log(e);
    }
  };

export const authenticateAsync = async (
  params: ServerTypes.ApiRequestAuthenticate["payload"]
): Promise<ServerTypes.ApiResponseAuthenticate> => {
  try {
    const requestPayload: ServerTypes.ApiRequest = {
      action: "Authenticate",
      payload: params,
    };

    return await fetch(serverUrl, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ event: requestPayload }),
    }).then((res) => useAuthorized(res));
  } catch (e) {
    __DEV__ && console.log(e);
  }
};

export const addSpeakerLabelsAsync = async (
  params: ServerTypes.ApiRequestAddSpeakerLabels["payload"]
): Promise<ServerTypes.ApiResponseAddSpeakerLabels> => {
  try {
    const requestPayload: ServerTypes.ApiRequest = {
      action: "AddSpeakerLabels",
      payload: params,
    };

    return await fetch(serverUrl, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: await getTokenAsync(),
      },
      body: JSON.stringify({ event: requestPayload }),
    }).then((res) => useAuthorized(res));
  } catch (e) {
    __DEV__ && console.log(e);
  }
};

export const getUserAccessAsync = async (
  params: ServerTypes.ApiRequestGetUserAccess["payload"]
): Promise<ServerTypes.ApiResponseGetUserAccess> => {
  try {
    const requestPayload: ServerTypes.ApiRequest = {
      action: "GetUserAccess",
      payload: params,
    };

    return await fetch(serverUrl, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: await getTokenAsync(),
      },
      body: JSON.stringify({ event: requestPayload }),
    }).then((res) => useAuthorized(res));
  } catch (e) {
    __DEV__ && console.log(e);
  }
};

export const recordMobilePurchaseAsync = async (
  params: ServerTypes.ApiRequestRecordMobilePurchase["payload"]
): Promise<ServerTypes.ApiResponseRecordMobilePurchase> => {
  try {
    const requestPayload: ServerTypes.ApiRequest = {
      action: "RecordMobilePurchase",
      payload: params,
    };

    return await fetch(serverUrl, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: await getTokenAsync(),
      },
      body: JSON.stringify({ event: requestPayload }),
    }).then((res) => useAuthorized(res));
  } catch (e) {
    __DEV__ && console.log(e);
  }
};

export const restorePurchasesAsync = async (
  params: ServerTypes.ApiRequestMigrateMobileUser["payload"]
): Promise<ServerTypes.ApiResponseMigrateMobileUser> => {
  try {
    const requestPayload: ServerTypes.ApiRequest = {
      action: "MigrateMobileUser",
      payload: params,
    };

    return await fetch(serverUrl, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: await getTokenAsync(),
      },
      body: JSON.stringify({ event: requestPayload }),
    }).then((res) => useAuthorized(res));
  } catch (e) {
    __DEV__ && console.log(e);
  }
};

export const getManageSubscriptionLinkAsync = async (
  params: ServerTypes.ApiRequestGetManageSubscriptionLink["payload"]
): Promise<ServerTypes.ApiResponseGetManageSubscriptionLink> => {
  try {
    const requestPayload: ServerTypes.ApiRequest = {
      action: "GetManageSubscriptionLink",
      payload: params,
    };

    return await fetch(serverUrl, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: await getTokenAsync(),
      },
      body: JSON.stringify({ event: requestPayload }),
    }).then((res) => useAuthorized(res));
  } catch (e) {
    __DEV__ && console.log(e);
  }
};

export const uploadFileAsync = async (params: {
  fileId: string;
  body?: ArrayBuffer;
  fileExtension: string;
  signal?: AbortSignal;
  partNumber?: string;
  uploadId?: string;
  parts?: { PartNumber: number; ETag: string }[];
}): Promise<ServerTypes.ApiResponseUploadFile> => {
  try {
    const url = new URL(`${serverUrl}/upload-file`);
    url.searchParams.append("action", "UploadFile");
    url.searchParams.append("fileId", params.fileId);
    url.searchParams.append("fileExtension", params.fileExtension);
    if (params.partNumber) {
      url.searchParams.append("partNumber", params.partNumber);
    }
    if (params.parts) {
      url.searchParams.append("parts", JSON.stringify(params.parts));
    }
    if (params.uploadId) {
      url.searchParams.append("uploadId", params.uploadId);
    }

    return await fetch(url, {
      signal: params.signal,
      method: "POST",
      headers: {
        Authorization: await getTokenAsync(),
        Accept: "application/json",
        "Content-Type": "application/octet-stream",
      },
      body: params.body,
    }).then((res) => res.json());
  } catch (e) {
    __DEV__ && console.log(e);
    return { error: e, ok: false, action: "UploadFile" };
  }
};
